<script>
// import { api } from '@/http-common.js'
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async init (rowsPerPage, reportToggle) {
    const token = await authService.getTokenSilently()
    let url
    if (reportToggle) {
      url = process.env.VUE_APP_APIURL + 'articles/listings/admin?count=' + rowsPerPage + '&report=' + reportToggle
    } else {
      url = process.env.VUE_APP_APIURL + 'articles/listings/admin?count=' + rowsPerPage
    }
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getCat () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/support-type/admin'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getPreviousPage (rowsPerPage, searchURL, prevCursor, reportToggle) {
    const token = await authService.getTokenSilently()
    let url
    if (searchURL !== null) {
      url = process.env.VUE_APP_APIURL + 'articles/listings/admin' + searchURL + '&previous=' + prevCursor
    } else if (searchURL === null) {
      if (reportToggle) {
        url = process.env.VUE_APP_APIURL + 'articles/listings/admin?count=' + rowsPerPage + '&previous=' + prevCursor + '&report=' + reportToggle
      } else if (!reportToggle) {
        url = process.env.VUE_APP_APIURL + 'articles/listings/admin?count=' + rowsPerPage + '&previous=' + prevCursor
      }
    }
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getNextPage (rowsPerPage, searchURL, nextCursor, reportToggle) {
    const token = await authService.getTokenSilently()
    let url
    if (searchURL !== null) {
      url = process.env.VUE_APP_APIURL + 'articles/listings/admin' + searchURL + '&next=' + nextCursor
    } else if (searchURL === null) {
      if (reportToggle) {
        url = process.env.VUE_APP_APIURL + 'articles/listings/admin?count=' + rowsPerPage + '&next=' + nextCursor + '&report=' + reportToggle
      } else if (!reportToggle) {
        url = process.env.VUE_APP_APIURL + 'articles/listings/admin?count=' + rowsPerPage + '&next=' + nextCursor
      }
    }
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async filterTable (searchUrl) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + searchUrl
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getTags () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/tags/admin'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getSuperTags () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/supertags/admin'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
