<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async updatePortal (id, portal) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'portal-management/portals/' + id
    var portalData = {
      text: {
        id: portal.id,
        videoHeader: portal.videoHeader,
        supportHeader: portal.supportHeader,
        homePageHeader: portal.homePageHeader,
        resourceHeader: portal.resourceHeader,
        videoTagLine: portal.videoTagLine,
        supportTagLine: portal.supportTagLine,
        resourceTagLine: portal.resourceTagLine,
        mainTagLine: portal.mainTagLine,
        topTagLine: portal.topTagLine,
        bottomTagLine: portal.bottomTagLine,
        topPickVideos: portal.topPickVideos,
        topPickListings: portal.topPickListings,
        topPickResources: portal.topPickResources,
        externalLinkText: portal.externalLinkText,
        externalLinkURL: portal.externalLinkURL,
        ctaLink: portal.ctaLink,
        ctaImage: portal.ctaImage,
        ctaTitle: portal.ctaTitle
      }
    }
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      data: portalData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      // eslint-disable-next-line
      console.error(error.response)
      return error.response
    }
  }
}
</script>
