<script>
// import { api } from '@/http-common.js'
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async getPortals (count) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'portal-management/portals?count=' + count
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getNextPage (count, nextCursor) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'portal-management/portals?count=' + count + '&next=' + nextCursor
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getPreviousPage (count, prevCursor) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'portal-management/portals?count=' + count + '&previous=' + prevCursor
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getPortalDetails (id) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'portal-management/portals/' + id + '/admin'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getUserPortalDetails (id) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'portal-management/portals/' + id
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
